
import TrainingDataModel from './TrainingDataModel';
import './TrainingListPageStyle.css';
// import training1img from '../Assets/EthicalHackingandpenetrationtesting101.jpg';
// import training2img from '../Assets/AdvanceEthicalHackingandpenetrationtesting.jpg';
// import training3img from '../Assets/AndroidpenetrationTesting.jpg';
// import training4img from '../Assets/APIPenetrationTesting.jpg';
import training5img from '../Assets/IOSPneetrationTesting.jpg';
// import training6img from '../Assets/ThickClientPenetrationTesting.jpg';
// import training7img from '../Assets/BasicandAdvanceSecurityOpreationSoc.jpg';
// import training8img from '../Assets/ActiveDirectorypentesting.jpg';
import Hacking101 from '../Assets/Hacking101.svg';
import ApiPenetration from '../Assets/ApiPenetration.svg';
import ActiveDirectory from '../Assets/ActiveDirectory.svg';
import AndroidPenetration from '../Assets/AndroidPenetration.svg';
import Svg1 from '../Assets/Svg2.svg';
import SOCImg from '../Assets/SOC_101.png';
import thickClient from '../Assets/thickClientPenetration.svg';

function TrainingListPage(){

    // const courseData = {
    //     'course-1': {
    //         title: 'HackersCrowd: Ethical Hacking 101',
    //         description: 'Comprehensive course covering ethical hacking fundamentals, networking, web security, and penetration testing techniques.',
    //         image: training1img,
    //         sections: [
    //         ]
    //     },
    //     'course-2': {
    //         title: 'Advanced Ethical Hacking and Penetration Testing',
    //         description: 'Advanced techniques in ethical hacking and comprehensive penetration testing methodologies.',
    //         image: training3img,
    //         sections: [
    //         ]
    //     },
    //     'course-3': {
    //         title: 'Android Penetration Testing',
    //         description: 'In-depth course on mobile security and Android penetration testing techniques.',
    //         image: training4img,
    //         sections: [
    //         ]
    //     },
    //     'course-4': {
    //         title: 'API Penetration Testing',
    //         description: 'Comprehensive course on identifying and exploiting API vulnerabilities.',
    //         image: training5img,
    //         sections: [
    //         ]
    //     },
    //     'course-5': {
    //         title: 'Basic and Advanced Security Operations (SOC)',
    //         description: 'Complete Security Operations Center (SOC) training from basics to advanced levels.',
    //         image: training7img,
    //         sections: [
    //         ]
    //     }
    // };

    return (
        <>
            
            <section className='page-title' >
                <div className="title-container">
                    <h1 className="title-container-content">Cybersecurity Training</h1>
                </div>
            </section>
            <section className='training-list-section'>
                <div className="training-list-container">
                    <div className='training-list-container-row1'>
                        <TrainingDataModel 
                            key="course-01"
                            title="Hc : Ethical Hacking and penetration testing 101"
                            body="&quot;HC: Ethical Hacking and Penetration Testing 101&quot; teaches foundational skills in cybersecurity, 
                                focusing on basic ethical hacking techniques and penetration testing methodologies."
                            linkText="Learn More"
                            linkUrl="/trainingdetails/course-01"
                            backgroundImage={Hacking101}
                        />
                        <TrainingDataModel 
                            key="course-02"
                            title="Hc : Advance Ethical Hacking and penetration testing"
                            body="&quot;Advanced Ethical Hacking and Penetration Testing&quot;
                                 focus on identification and exploitation of web based vulnerabilities."
                            linkText="Learn More"
                            linkUrl="/trainingdetails/course-02"
                            backgroundImage={Svg1}
                        />
                        
                    </div>
                    <div className='training-list-container-row2'>
                        <TrainingDataModel 
                            key="course-03"
                            title="Android penetration Testing"
                            body="&quot;Android Penetration Testing&quot; focuses on identifying and exploiting security vulnerabilities in Android applications and devices. 
                            The course covers a range of tools and techniques for testing and securing mobile apps in the Android ecosystem."
                            linkText="Learn More"
                            linkUrl="/trainingdetails/course-03"
                            backgroundImage={AndroidPenetration}
                            sectionCount={5}
                        />
                        <TrainingDataModel 
                            title="API Penetration Testing"
                            body="&quot;API Penetration Testing &quot; covers techniques for identifying and exploiting vulnerabilities in APIs to ensure their security. 
                            The course teaches methods for assessing API security through various tools and testing strategies, focusing on real-world attack scenarios."
                            linkText="Learn More"
                            linkUrl="/trainingdetails"
                            backgroundImage={ApiPenetration}
                            sectionCount={5}
                        />
                        
                        
                    </div>
                    <div className='training-list-container-row2'>
                        <TrainingDataModel 
                            title="IOS Penetration Testing"
                            body="&quot;iOS Penetration Testing&quot; provides an in-depth approach to identifying and exploiting vulnerabilities in iOS applications and devices. 
                            The course covers security testing methodologies, tools, and techniques to help secure mobile apps in the iOS ecosystem"
                            linkText="Learn More"
                            linkUrl="/trainingdetails"
                            backgroundImage={training5img}
                            sectionCount={5}
                        />
                        <TrainingDataModel 
                            title="Thick Client Penetration Testing"
                            body="&quot;Basic to Advance SOC&quot; provides comprehensive training on managing and securing Security Operations Centers, from foundational to advanced levels."
                            linkText="Learn More"
                            linkUrl="/trainingdetails"
                            backgroundImage={thickClient}
                            sectionCount={5}
                        />
                        
                    </div>
                    <div className='training-list-container-row3'>
                        <TrainingDataModel 
                            title="Hc :- Basic and Advance Security Opreation (Soc)"
                            body="&quot;Basic to Advance SOC&quot; provides comprehensive training on managing and securing Security Operations Centers, from foundational to advanced levels."
                            linkText="Learn More"
                            linkUrl="/trainingdetails"
                            backgroundImage={SOCImg}
                            sectionCount={5}
                        />
                        <TrainingDataModel 
                            title="HC : Active Directory pentesting"
                            body="&quot;Active Directory&quot; focuses on techniques for assessing, managing, and securing Active Directory environments in enterprise networks."
                            linkText="Learn More"
                            linkUrl="/trainingdetails"
                            backgroundImage={ActiveDirectory}
                            sectionCount={5}
                        />
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default TrainingListPage;