import { Link } from 'react-router-dom';

import './TrainingDataModelStyle.css';

function TrainingDataModel({ title, body, linkText, linkUrl, backgroundImage, courseData }) {
    return (
        // <div
        //     className="card"
        // >
        //     <div className="card-content">
        //         <img className='card-img' src={backgroundImage} alt="Coming Soon..." />
        //         <h2 className="card-title">
        //             <Link
        //                 className='card-title-link'
        //                 to={{
        //                 pathname: linkUrl.startsWith("#") ? "/" : linkUrl, 
        //                 state: {courseData },
        //                 }}
        //             >
        //                 {title}
        //             </Link>
        //         </h2>
        //         <p className="card-body">
        //             {body}
        //         </p>
        //     </div>
        // </div>
        // <div className="card">
        //         <img className="card-image" src={backgroundImage} alt="Coming Soon..." />
           
        //     <div className="card-content">
        //         <h2 className="card-title">
        //             <Link
        //                 className='card-title-link'
        //                 to={{
        //                     pathname: linkUrl.startsWith("#") ? "/" : linkUrl,
        //                     state: {courseData }
        //                 }}
        //             >
        //                 {title}
        //             </Link>
        //         </h2>
        //         <p className="card-body">
        //             {body}
        //         </p>
        //     </div>
        // </div>
        <div className="container">
            <div className="image-container">
            <img src={backgroundImage} alt="Hacker image" />
            </div>
            <h1 className='course-title-h1'>
                <Link
                        className='card-title-link'
                        to={{
                            pathname: linkUrl.startsWith("#") ? "/" : linkUrl,
                            state: {courseData }
                        }}
                    >
                    {title}
                </Link>
            </h1>
            <p className='course-title-p'>{body}</p>
        </div>
    );
}

export default TrainingDataModel;
