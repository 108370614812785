import './TrainingDetailsPageStyle.css';
import React, { useState } from "react";
// import TrainingCyber from '../Assets/trainingCyber.jpeg';
import TrainingCyber from '../Assets/AndroidpenetrationTesting1.svg';
import { useLocation,useParams } from 'react-router-dom';
import training3img from '../Assets/AndroidpenetrationTesting.jpg';
import training4img from '../Assets/APIPenetrationTesting.jpg';
import training5img from '../Assets/IOSPneetrationTesting.jpg';

import Hacking101 from '../Assets/Hacking101.svg';
import ApiPenetration from '../Assets/ApiPenetration.svg';
import ActiveDirectory from '../Assets/ActiveDirectory.svg';
import AndroidPenetration from '../Assets/AndroidPenetration.svg';
import Svg1 from '../Assets/Svg2.svg';
import SOCImg from '../Assets/SOC_101.png';
import thickClient from '../Assets/thickClientPenetration.svg';




function TrainingDetailsPage(){
    const location = useLocation();
    const { courseId } = useParams();
    const course_id=courseId;
    // console.log(courseId)
   

    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (sectionId) => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [sectionId]: !prevState[sectionId], // Toggle the current section's state
        }));
    };

    // const courseData01 = {
    //     courseId: 'course-01',
    //     title: 'HackersCrowd: Ethical Hacking 101',
    //     description: 'Comprehensive course covering ethical hacking fundamentals, networking, web security, and penetration testing techniques.',
    //     image: Hacking101,
    //     // image: Svg1,
    //     sections: [
    //         {
    //             id: 1,
    //             title: 'Training Introduction',
    //             points: []
    //         },
    //         {
    //             id: 2,
    //             title: 'Training Discord',
    //             points: []
    //         },
    //         {
    //             id: 3,
    //             title: 'Introduction to Networking',
    //             points: [
    //                 'IP Address & Subnetting',
    //                 'MAC Addresses',
    //                 'Routing & Network Protocols',
    //                 'OSI Model & TCP/IP Model',
    //                 'Network Security: Firewall, IDS, IPS, VPN',
    //                 'Wireless Networking & Wi-Fi Standards'
    //             ]
    //         },
    //         {
    //             id: 4,
    //             title: 'Lab Setup',
    //             points: [
    //                 'Installing VM Ware',
    //                 'Configurations',
    //                 'OS: Kali Linux/ Parrot OS',
    //                 'Tools Installation'
    //             ]
    //         },
    //         {
    //             id: 5,
    //             title: 'Linux & CLI',
    //             points: [
    //                 'Introduction to CLI',
    //                 'Linux File system',
    //                 'Device Partition & Mounting',
    //                 'Working with Files and directories',
    //                 'Working with Users & Group',
    //                 'File and Directory Permission',
    //                 'Common Commands',
    //                 'Environment Variables',
    //                 'Redirecting Input & Output',
    //                 'Converting Text Files'
    //             ]
    //         },
    //         {
    //             id: 6,
    //             title: 'Web Fundamentals',
    //             points: [
    //                 'Client server architecture',
    //                 'Internet Based Services',
    //                 'HTTP & HTTPS',
    //                 'URI',
    //                 'Web Server & Web Browser',
    //                 'Hyperlink',
    //                 'DNS',
    //                 'How Web Works',
    //                 'HTTP Methods'
    //             ]
    //         },
    //         {
    //             id: 7,
    //             title: 'Social Engineering',
    //             points: [
    //                 'Introduction to Phishing Attack',
    //                 'Practical Phishing Attack',
    //                 'Introduction to RATs',
    //                 'Practical RAT Attack',
    //                 'Exercise: Facebook & Instagram Phishing Attack'
    //             ]
    //         },
    //         {
    //             id: 8,
    //             title: 'OWASP Top 10',
    //             points: [
    //                 'Broken Access Control',
    //                 'Cryptographic Failures',
    //                 'Injection Attacks',
    //                 'Insecure Designs',
    //                 'Security Misconfigurations',
    //                 'Vulnerable and Outdated Components',
    //                 'Identification & Authentication Failures',
    //                 'Software & Data Integrity Failures',
    //                 'Security Monitoring & Logging Failures',
    //                 'Server-Side Request Forgery (SSRF)'
    //             ]
    //         },
    //         {
    //             id: 9,
    //             title: 'Approach and Methodology',
    //             points: [
    //                 'Introduction to Web Penetration Testing',
    //                 'Network Scanning & Enumeration',
    //                 'Information Gathering (Recon)',
    //                 '- Active Recon',
    //                 '- Passive Recon',
    //                 'Automated Vulnerability Scanning using Nessus & Acunetix',
    //                 'Exploitation'
    //             ]
    //         },
    //         {
    //             id: 10,
    //             title: 'Practical Demonstration of OWASP Top 10',
    //             points: [
    //                 'Cross Site Scripting',
    //                 '- Reflected XSS',
    //                 '- Stored XSS',
    //                 '- DOM Based XSS',
    //                 'SQL Injection',
    //                 'XXE: XML External Entity',
    //                 'Server-Side Request Forgery (SSRF)',
    //                 'OS Command Injection',
    //                 'Path Traversal',
    //                 'Broken Access Control',
    //                 'IDOR',
    //                 'Subdomain Takeover',
    //                 'Weak Session Management',
    //                 'Username Enumeration',
    //                 'Brute-Force Attack',
    //                 'No-Rate Limiting',
    //                 'Host Header Injection',
    //                 'CORS',
    //                 'Misconfigured DMRAC',
    //                 'File Upload Vulnerabilities'
    //             ]
    //         },
    //         {
    //             id: 11,
    //             title: 'Advanced Attacks',
    //             points: [
    //                 'Server-Side Template Injection',
    //                 'Web LLM Attacks'
    //             ]
    //         },
    //         {
    //             id: 12,
    //             title: 'Demo on Live Websites',
    //             points: []
    //         },
    //         {
    //             id: 13,
    //             title: 'Introduction to Bug Bounty',
    //             points: [
    //                 'How to Hunt?',
    //                 'Where to Hunt?',
    //                 'Report the Findings'
    //             ]
    //         },
    //         {
    //             id: 14,
    //             title: 'Assessment',
    //             points: []
    //         },
    //         {
    //             id: 15,
    //             title: 'Conclusion',
    //             points: []
    //         }
    //     ]
    // };

    const courseData01 = {
        courseId: 'course-01',
        title: 'HackersCrowd: Ethical Hacking 101',
        description: 'Comprehensive course covering ethical hacking fundamentals, networking, web security, and penetration testing techniques.',
        image: Hacking101, // image: Svg1,
        sections: [
            { id: 1, title: 'Training Introduction', points: [] },
            { id: 2, title: 'Training Discord', points: [] },
            { 
                id: 3, 
                title: 'Introduction to Networking', 
                points: [
                    'IP Address & Subnetting',
                    'MAC Addresses',
                    'Routing & Network Protocols',
                    'OSI Model & TCP/IP Model',
                    'Network Security: Firewall, IDS, IPS, VPN',
                    'Wireless Networking & Wi-Fi Standards'
                ]
            },
            { 
                id: 4, 
                title: 'Lab Setup', 
                points: [
                    'Installing VM Ware',
                    'Configurations',
                    'OS: Kali Linux/ Parrot OS',
                    'Tools Installation'
                ]
            },
            { 
                id: 5, 
                title: 'Linux & CLI', 
                points: [
                    'Introduction to CLI',
                    'Linux File system',
                    'Device Partition & Mounting',
                    'Working with Files and directories',
                    'Working with Users & Group',
                    'File and Directory Permission',
                    'Common Commands',
                    'Environment Variables',
                    'Redirecting Input & Output',
                    'Converting Text Files'
                ]
            },
            { 
                id: 6, 
                title: 'Web Fundamentals', 
                points: [
                    'Client server architecture',
                    'Internet Based Services',
                    'HTTP & HTTPS',
                    'URI',
                    'Web Server & Web Browser',
                    'Hyperlink',
                    'DNS',
                    'How Web Works',
                    'HTTP Methods'
                ]
            },
            { 
                id: 7, 
                title: 'Social Engineering', 
                points: [
                    'Introduction to Phishing Attack',
                    'Practical Phishing Attack',
                    'Introduction to RATs',
                    'Practical RAT Attack',
                    'Exercise: Facebook & Instagram Phishing Attack'
                ]
            },
            { 
                id: 8, 
                title: 'OWASP Top 10', 
                points: [
                    'Broken Access Control',
                    'Cryptographic Failures',
                    'Injection Attacks',
                    'Insecure Designs',
                    'Security Misconfigurations',
                    'Vulnerable and Outdated Components',
                    'Identification & Authentication Failures',
                    'Software & Data Integrity Failures',
                    'Security Monitoring & Logging Failures',
                    'Server-Side Request Forgery (SSRF)'
                ]
            },
            { 
                id: 9, 
                title: 'Approach and Methodology', 
                points: [
                    'Introduction to Web Penetration Testing',
                    'Network Scanning & Enumeration',
                    'Information Gathering (Recon)',
                    '- Active Recon',
                    '- Passive Recon',
                    'Automated Vulnerability Scanning using Nessus & Acunetix',
                    'Exploitation'
                ]
            },
            { 
                id: 10, 
                title: 'Practical Demonstration of OWASP Top 10', 
                points: [
                    'Cross Site Scripting',
                    '- Reflected XSS',
                    '- Stored XSS',
                    '- DOM Based XSS',
                    'SQL Injection',
                    'XXE: XML External Entity',
                    'Server-Side Request Forgery (SSRF)',
                    'OS Command Injection',
                    'Path Traversal',
                    'Broken Access Control',
                    'IDOR',
                    'Subdomain Takeover',
                    'Weak Session Management',
                    'Username Enumeration',
                    'Brute-Force Attack',
                    'No-Rate Limiting',
                    'Host Header Injection',
                    'CORS',
                    'Misconfigured DMRAC',
                    'File Upload Vulnerabilities'
                ]
            },
            { 
                id: 11, 
                title: 'Advanced Attacks', 
                points: [
                    'Server-Side Template Injection',
                    'Web LLM Attacks'
                ]
            },
            { id: 12, title: 'Demo on Live Websites', points: [] },
            { 
                id: 13, 
                title: 'Introduction to Bug Bounty', 
                points: [
                    'How to Hunt?',
                    'Where to Hunt?',
                    'Report the Findings'
                ]
            },
            { id: 14, title: 'Assessment', points: [] },
            { id: 15, title: 'Conclusion', points: [] }
        ]
    };
    
    // const courseData02 = {
    //     courseId: 'course-02',
    //     title: 'Advance Ethical Hacking and penetration testing',
    //     description: 'Hands-on course focusing on securing networks, firewalls, intrusion detection, and implementing robust security protocols.',
    //     image: {Svg1},
    //     sections: [
    //         { id: 1, title: 'Course Overview', points: [] },
    //         { id: 2, title: 'Understanding Cyber Threats', points: [] },
    //         {
    //             id: 3, 
    //             title: 'Network Security Basics', 
    //             points: [
    //                 'Firewall Basics',
    //                 'Network Traffic Analysis',
    //                 'Secure Network Architectures',
    //                 'IDS/IPS Systems',
    //                 'Wireless Security Protocols'
    //             ]
    //         },
    //         {
    //             id: 4,
    //             title: 'Endpoint Protection',
    //             points: [
    //                 'Endpoint Security Tools',
    //                 'Device Hardening',
    //                 'Antivirus and Antimalware Tools',
    //                 'BYOD Policies'
    //             ]
    //         },
    //         // Add more sections as needed
    //     ]
    // };
    
    const courseData02 = {
        courseId: 'course-02',
        title: 'Advance Web Hacking and Penetration Testing',
        description: 'A detailed course focusing on advanced web hacking techniques, penetration testing, and real-world challenges.',
        image: Svg1, // Replace with an appropriate image variable
        sections: [
            { id: 1, title: 'Introduction To Advance Web Hacking', points: [] },
            { id: 2, title: 'Content Discovery', points: [] },
            { 
                id: 3, 
                title: 'Passive Recon', 
                points: [
                    'Passive Subdomain Enum',
                    'Passive Endpoint Enum',
                    'Passive URL Enum'
                ] 
            },
            { 
                id: 4, 
                title: 'Active Recon', 
                points: [
                    'Active Subdomain Enum',
                    'Active Endpoint Enum',
                    'Active URL Enum'
                ] 
            },
            { 
                id: 5, 
                title: 'Networking', 
                points: [
                    'Protocols and Servers',
                    'Protocols and Servers 2',
                    'Networking Challenge CTF'
                ] 
            },
            { 
                id: 6, 
                title: 'Nmap Introduction', 
                points: [
                    'Nmap Live Host Discovery',
                    'Nmap Basic Port Scans',
                    'Nmap Advanced Port Scans',
                    'Nmap Post Port Scans'
                ] 
            },
            { 
                id: 7, 
                title: 'Authentication Bypass', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 8, 
                title: 'Advance IDOR Techniques', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 9, 
                title: 'Privilege Escalation', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 10, 
                title: 'SSRF', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 11, 
                title: 'Advance SQL Injection', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2',
                    'Practical Lab 3',
                    'Practical Lab 4'
                ] 
            },
            { 
                id: 12, 
                title: 'Local File Inclusion', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 13, 
                title: 'Remote File Inclusion', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 14, 
                title: 'Command Injection', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 15, 
                title: 'Basic CTF Challenges', 
                points: [
                    'Basic CTF 1',
                    'Basic CTF 2'
                ] 
            },
            { 
                id: 16, 
                title: 'Moderate CTF Challenges', 
                points: [
                    'Moderate CTF 3',
                    'Moderate CTF 4'
                ] 
            },
            { 
                id: 17, 
                title: 'XSS', 
                points: [
                    'Reflected XSS',
                    'Stored XSS',
                    'Blind XSS',
                    'DOM XSS'
                ] 
            },
            { id: 18, title: 'Blind SSRF', points: [] },
            { 
                id: 19, 
                title: 'Introduction to Metasploit', 
                points: [
                    'Exploitation using Metasploit',
                    'Post Exploitation Techniques',
                    'Metasploit for Vulnerability Assessment',
                    'Custom Payload Development'
                ] 
            },
            { id: 20, title: 'Brute Force', points: [] },
            { id: 21, title: 'Rate Limit Bypass', points: [] },
            { id: 22, title: 'Session Management', points: [] },
            { 
                id: 23, 
                title: 'JWT Security', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 24, 
                title: 'OAuth Vulnerabilities', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 25, 
                title: 'What is MFA', 
                points: [
                    'MFA Bypass 1',
                    'MFA Bypass 2',
                    'MFA Bypass 3',
                    'MFA Bypass 4'
                ] 
            },
            { id: 26, title: 'LDAP Injection', points: [] },
            { 
                id: 27, 
                title: 'Advance XXE', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { id: 28, title: 'ORM Injection', points: [] },
            { id: 29, title: 'Insecure Deserialization', points: [] },
            { 
                id: 30, 
                title: 'Prototype Pollution', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { 
                id: 31, 
                title: 'Request Smuggling', 
                points: [
                    'Practical Lab 1',
                    'Practical Lab 2'
                ] 
            },
            { id: 32, title: 'Google Dorking', points: [] },
            { id: 33, title: 'How to Hunt for Bugs', points: [] },
            { id: 34, title: 'Finding Public and Self-Hosted Programs', points: [] },
            { id: 35, title: 'Conclusion', points: [] }
        ]
    };
    
    const courseData03 = {
        courseId: 'course-03',
        title: 'Android penetration Testing',
        description: 'In-depth training on data privacy regulations, encryption, data masking, and GDPR compliance.',
        image: {AndroidPenetration},
        sections: [
            { id: 1, title: 'Introduction to Data Privacy', points: [] },
            { id: 2, title: 'Understanding GDPR', points: [] },
            {
                id: 3, 
                title: 'Data Encryption Techniques', 
                points: [
                    'Symmetric vs Asymmetric Encryption',
                    'TLS and SSL Basics',
                    'Public Key Infrastructure',
                    'Data at Rest Encryption'
                ]
            },
            // Add more sections as needed
        ]
    };

   
    // Add courseData04 through courseData08 with unique titles, descriptions, and sections similar to the examples above.
    
    const defaultCourseData = {
        courseId: 'default-course',
        title: 'Default Course',
        description: 'Default course description.',
        image: "",
        sections: [
            { id: 1, title: 'Default Section 1', points: [] },
            { id: 2, title: 'Default Section 2', points: [] },
            { id: 3, title: 'Default Section 3', points: [] },
        ]
    };
    

    // data set


    // data set ends here
    const allCourses = [
        courseData01,
        courseData02,
        courseData03,
        defaultCourseData
    ];
    // const getCourseData = (courseId) => {
    //     return allCourses.find(course => course.courseId === courseId) || defaultCourseData;
    // };
    const getCourseData = (courseId) => {
        const course = allCourses.find(course => course.courseId === courseId);
        if (!course) {
            console.warn(`Course with ID "${courseId}" not found. Falling back to default.`);
            courseId = "default-course"; // Set to default if not found
            return defaultCourseData;
        }
        return course;
    };

    console.log(course_id)
    const courseData =getCourseData(course_id)

    return (
        <>
            <section className="TrainingDetailsPageSection">
            {/* Row 1 */}
            <div className="TrainingDetailsPageSectionrow1">
                <div className="details-section">
                    <h1 className="title">{courseData.title}</h1>
                    <p className="description">
                        {courseData.description}
                    </p>
                </div>
                <div className="details-section-Img">
                    <img 
                            src={courseData.image || TrainingCyber} 
                            alt="Training Image" 
                            className="training-image" 
                        />
                </div>
            </div>

            {/* Row 2 */}
            <div className="TrainingDetailsPageSectionrow2">
                <div className="summary-tab">
                    <h2 className="tab-label">Summary</h2>
                    <ul className="sections-list">
                        {courseData.sections && courseData.sections.map((section,index) => (
                            <li
                                key={section.id}
                                className={`section-item ${
                                    section.points && section.points.length > 0 && expandedSections[section.id] 
                                        ? "expanded" 
                                        : ""
                                }`}
                            >
                                <button
                                    className="section-toggle"
                                    onClick={() => section.points && section.points.length > 0 && toggleSection(section.id)}
                                    style={{
                                        cursor: section.points && section.points.length > 0 
                                            ? 'pointer' 
                                            : 'default'
                                    }}
                                >
                                    {/* <span>{section.title} &nbsp;</span> */}
                                    <span>{`${index + 1}. ${section.title}`} &nbsp;</span>
                                    {section.points && section.points.length > 0 && (
                                        <span className="arrow">&#9660;</span>
                                    )}
                                </button>
                                {section.points && section.points.length > 0 && (
                                    <ul className="section-data">
                                        {section.points.map((point, index) => (
                                            <li key={index}>{point}</li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>

        </>
    )
}

export default TrainingDetailsPage;