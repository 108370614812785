
import './App.css';
import Footer from './Components/Footer';
import { Routes, Route, Navigate,useLocation } from "react-router-dom";
import Header from './Components/Header';
import TrainingListPage from './Components/TrainingListPAge';
import { BrowserRouter as Router } from 'react-router-dom';
import TrainingDetailsPage from './Components/TrainingDetailsPage';

function App() {
  const location = useLocation();
  const isNotFound = location.pathname === "/404";
  return (
    <>
    <div className='App'>
      <Header />
      {/* <TrainingListPage /> */}
        <Routes>
            <Route exact path="/" element={<TrainingListPage />} />
            <Route exact path="/trainingdetails/:courseId" element={<TrainingDetailsPage />} />
        </Routes>
      <Footer />
    </div>
    </>
    
  );
}

export default App;
