
import { Link } from 'react-router-dom';
import HeaderLogo from '../Assets/HC_Logo.png';

function Header(){
    return (
        <>
            <header>
                <nav className="headerNav">
                    <div className="headerLogo">
                        <img className='headerLogoImg' src={HeaderLogo} alt="" />
                        <p className='logo-text'>Academy</p>
                    </div>
                    <div className="headerMenu ">
                        <Link className="certifications">Certifications</Link>
                        <Link className="Paths">Paths</Link>
                        <Link className="Modules">Modules</Link>
                        <Link className="Business" to="https://www.hackerscrowd.com/">Business</Link>
                        <Link className="labs"> Labs</Link>
                        <Link className="Faq" to="https://www.hackerscrowd.com/faqs/">FAQ</Link>
                        <Link className="News" to="https://www.hackerscrowd.com/blogs/">News</Link>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;