import { Link } from "react-router-dom";
function Footer(){
    return (
        <>
            <footer className="footer">
                <div className="footer-container">
                    <div className="row-1 ">
                    <div className="footer-container-col">  
                        <h4>Solutions</h4>
                        <ul>
                        <li>Application Services</li>
                        <li>Security Servers</li>
                        <li>Security Firewalls</li>
                        <li>Cloud Services</li>
                        <li>Integrations</li>
                        </ul>
                    </div>
                    <div className="footer-container-col">
                        <h4>Partners</h4>
                        <ul>
                        <li>Amazon</li>
                        <li>Figina</li>
                        <li>Twitter</li>
                        <li>CloudRail-BOT</li>
                        </ul>
                    </div>
                    <div className="footer-container-col">
                        <h4>
                            <Link className="footer-links" to="">
                                Resources
                           </Link>
                        </h4>
                        <ul>
                        <li>
                            <Link className="footer-links" to="">
                                Academy
                           </Link>
                        </li>
                        <li>
                            <Link className="footer-links" to="https://www.hackerscrowd.com/blogs/">
                                Blog
                            </Link>
                            
                        </li>
                        <li>
                            <Link className="footer-links" to="">
                              Support
                           </Link>
                        </li>
                        <li>
                            <Link className="footer-links" to="">
                                Teams
                           </Link>
                        </li>
                        </ul>
                    </div>
                    <div className="footer-container-col">
                        <h4>
                            <Link className="footer-links" to="">
                                Company
                           </Link>
                        </h4>
                        <ul>
                        <li>
                            <Link className="footer-links" to="https://www.hackerscrowd.com/about-us/">
                                About Us
                           </Link>
                        </li>
                        <li>
                            <Link className="footer-links" to="">
                                Careers
                           </Link>
                        </li>
                        <li>
                           <Link className="footer-links" to="https://www.hackerscrowd.com/faqs/">
                           FAQs
                           </Link>
                            </li>
                        <li>
                            <Link className="footer-links" to="https://www.hackerscrowd.com/contact-us/">
                                Contact Us
                           </Link>
                        </li>
                        </ul>
                    </div>
                    <div className="footer-container-col">
                        <h4>
                            <Link className="footer-links" to="https://www.hackerscrowd.com/contact-us/">
                                Contact Us
                            </Link>
                        </h4>
                        <ul>
                        <li>8708104956</li>
                        <li>Support@hackerscrowd.com</li>
                        <li>ITPL, Banglore</li>
                        </ul>
                    </div>
                    </div>
                    <div className="row-2">
                    <div className="vertical-line"></div>
                    </div>
                    
                    <div className="row-3">
                    <div className="rights-reserved">
                        &copy; 2023 All Rights Reserved
                        
                    </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;